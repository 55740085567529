import React from 'react'
import Home from '../Components/Home'
import ComingSoon from '../Components/ComingSoon'

const HomePage = () => {
  return (
    <>
      <Home />
      <ComingSoon />
    </>
  )
}

export default HomePage
